import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><inlineCode parentName="p">{`step-ca`}</inlineCode>{` is an online Certificate Authority (CA) for secure, automated X.509 and SSH certificate management. People use our tooling to automate issuance, renewal, and revocation of certificates for all types of workloads and use cases. This tutorial section highlights many popular deployment scenarios and is designed to help the participant learn by doing. Each activity includes detailed examples and copy/paste code blocks. `}</p>
    <p>{`In general, these tutorials assume you have initialized and started up a `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` instance using the steps in `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/getting-started"
      }}>{`Getting Started`}</a>{`. As an alternative, you can use our hosted CA, `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/certificate-manager"
      }}>{`Smallstep Certificate Manager`}</a>{`.`}</p>
    {
      /* ## Available PKI Tutorials */
    }
    {
      /* <Contents product="tutorials" /> */
    }
    <h2>{`Further Examples & Tutorials`}</h2>
    <p>{`Beyond these docs, we have the following resources available:`}</p>
    <ul>
      <li parentName="ul">{`If you are new to PKI, we would recommend starting with `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/blog/everything-pki/"
        }}>{`Everything you should know about certificates and PKI but are too afraid to ask.`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/blog"
        }}>{`Our blog`}</a>{` often features specific walk-throughs and integrations.`}</li>
      <li parentName="ul">{`We maintain `}<a parentName="li" {...{
          "href": "https://github.com/smallstep/certificates/discussions/765"
        }}>{`a list of community-contributed tutorials, examples, and integrations`}</a></li>
      <li parentName="ul">{`Our `}<a parentName="li" {...{
          "href": "https://github.com/smallstep/certificates/discussions"
        }}>{`GitHub Discussions`}</a>{` and `}<a parentName="li" {...{
          "href": "https://u.step.sm/discord"
        }}>{`Discord`}</a>{` are treasure troves for you to search and ask questions.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      